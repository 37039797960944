




















import Vue from "vue";
import screenshot from "../img/corsace-open.jpg";

export default Vue.extend({
    data: () => ({ screenshot }),
});
