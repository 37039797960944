













import Vue from "vue";
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Links from "./components/Links";

export default Vue.extend({
    components: { Header, Introduction, Skills, Projects, Links },
});
