














import Vue from "vue";
import photo from "../img/photo.jpg";

export default Vue.extend({
    data: () => ({ photo }),
});
