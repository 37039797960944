import Vue from "vue";

import App from "./App";

import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

import VueI18n from "vue-i18n";
import Locale from "./locale.json";
Vue.use(VueI18n);

const lang = localStorage.getItem("lang") || "fr";
const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: "fr",
    messages: Locale,
});

export default new Vue({
    i18n,
    el: "#app",
    render: (h) => h(App),
});
