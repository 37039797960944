






















import Vue from "vue";

export default Vue.extend({
    data: () => ({
        langs: [
            { value: "fr", text: `Français` },
            { value: "en", text: "English" },
        ],
    }),
    methods: {
        saveLocale: function(newLang: string) {
            localStorage.setItem("lang", newLang);
        },
    },
});
